@font-face {
  font-family: 'Abiah';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Abiah'),
    url('./assets/fonts/Abiah/Abiah-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Branch';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Branch'),
    url('./assets/fonts/Branch/Branch.otf') format('opentype');
}
@font-face {
  font-family: 'Sequel Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local('Sequel Sans'),
    url('./assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Light-Body.woff')
      format('woff');
}
@font-face {
  font-family: 'Sequel Sans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local('Sequel Sans'),
    url('./assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Light-Obl-Body.woff')
      format('woff');
}
@font-face {
  font-family: 'Sequel Sans';
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  src: local('Sequel Sans'),
    url('./assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Bold-Head.woff')
      format('woff');
}
@font-face {
  font-family: 'Sequel Sans';
  font-style: italic;
  font-weight: bold;
  font-display: auto;
  src: local('Sequel Sans'),
    url('./assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Bold-Obl-Head.woff')
      format('woff');
}
@font-face {
  font-family: 'Sequel Sans';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: local('Sequel Sans'),
    url('./assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Book-Head.woff')
      format('woff');
}
@font-face {
  font-family: 'Sequel Sans';
  font-style: italic;
  font-weight: normal;
  font-display: auto;
  src: local('Sequel Sans'),
    url('./assets/fonts/Sequel-Sans/OGJ-Type-Design-Sequel-Sans-Book-Obl-Head.woff')
      format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: italic;
  font-weight: bold;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Black-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Black.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: italic;
  font-weight: bold;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Bold-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: italic;
  font-weight: 200;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-ExtraLight-Italic.woff')
      format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: normal;
  font-weight: 200;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Light-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Light.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: italic;
  font-weight: 500;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Medium-Italic.woff')
      format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Regular-Italic.woff')
      format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-SemiBold-Italic.woff')
      format('woff');
}
@font-face {
  font-family: 'Silk Serif';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local('Silk Serif'),
    url('./assets/fonts/Silk-Serif/Silk-Serif-SemiBold.woff') format('woff');
}

body {
  background: #fdfcfb;
  color: black;
  font-family: 'Sequel Sans', 'Interstate', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a,
a:link,
a:visited {
  color: #a38c71;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.underline,
a.underline:link,
a.underline:visited {
  text-decoration: underline;
}
